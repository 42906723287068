import { useCallback, useEffect, useState } from 'react';
import type { RequestOptions } from '@algolia/transporter';
import algoliaInstance, { AlgoliaService } from '@source/services/Algolia';
import { TSort } from '@source/constants/common';
import {
  ALGOLIA_API_KEY_MAP,
  ALGOLIA_INDEXES_COUNTRIES,
  ALGOLIA_INDEX_POPULAR_BY_COUNTRY,
  ALGOLIA_INDEX_SIMILAR_CARS_BY_COUNTRY,
} from '@source/constants/algolia';
import { TRegionKey } from '@source/interface';
import Logger from '@source/services/Logger';
import useRegionConfig from '@source/hooks/useRegionConfig';
import { TSortBy } from '@source/pages/Home/components/SortV1.0/types';
import { NEXT_PUBLIC_ALGOLIA_APP_ID } from '@source/constants/env';

const useAlgoliaService = () => {
  const [instance] = useState<AlgoliaService>(algoliaInstance);
  const {
    countryConfig: { country: countryCode },
  } = useRegionConfig();

  useEffect(() => {
    const algoliaKey = countryCode ? ALGOLIA_API_KEY_MAP[countryCode] : null;

    if (algoliaKey) {
      Logger.log('[Reinit Algolia....] - Algolia key:', algoliaKey);
      instance?.reinit(NEXT_PUBLIC_ALGOLIA_APP_ID || '', algoliaKey || '');
    }
  }, [instance, countryCode]);

  const getAlgoliaIndexes = useCallback((countryId?: string, orderField = 'latest') => {
    type IndexesKey = keyof typeof ALGOLIA_INDEXES_COUNTRIES;
    type IndexesValue = typeof ALGOLIA_INDEXES_COUNTRIES[IndexesKey];
    const countryIndex = countryId;
    const indexes = ALGOLIA_INDEXES_COUNTRIES[countryIndex as IndexesKey];
    return indexes[orderField as keyof IndexesValue];
  }, []);

  const fetchAlgoliaData = useCallback(
    async (params: RequestOptions, sortBy?: TSortBy | null, query?: string, countryId?: string) => {
      Logger.log('Fetching Algolia Data...');
      const { field, order } = sortBy || {};
      const countryIndex = countryId || countryCode;
      const algoliaIndexes = getAlgoliaIndexes(countryIndex, field || 'latest');
      const indexName = algoliaIndexes?.[(order as keyof TSort) || 'asc'];
      const result = await instance.search(indexName, query || '', params);
      return result;
    },
    [instance, getAlgoliaIndexes, countryCode],
  );

  const fetchAlgoliaPopularCars = useCallback(
    (country?: TRegionKey) => {
      const countryIndex = country || countryCode;

      return algoliaInstance.search(ALGOLIA_INDEX_POPULAR_BY_COUNTRY[countryIndex as string], '', {});
    },
    [countryCode],
  );

  const fetchAlgoliaSimilarCars = useCallback(
    (slug: string) => algoliaInstance.getObject(ALGOLIA_INDEX_SIMILAR_CARS_BY_COUNTRY[countryCode as string], slug),
    [countryCode],
  );

  const getAutoCompleteListingTitle = useCallback(
    async (
      query: string,
      sortBy: TSortBy | null | undefined,
      hitsPerPage: number,
      facetsToRetrieve: string[],
      attributesToRetrieve?: string[],
    ) => {
      const params = {
        hitsPerPage: hitsPerPage || 18,
        attributesToRetrieve: attributesToRetrieve || ['inventory.make_model'],
        facets: facetsToRetrieve || ['inventory.make_model', 'categories'],
      };
      const { field, order } = sortBy || {};
      const countryIndex = countryCode;
      const algoliaIndexes = getAlgoliaIndexes(countryIndex, field);
      const indexName = algoliaIndexes?.[(order as keyof TSort) || 'asc'];
      const result = await algoliaInstance.search(indexName, query, params);

      const { facets } = result;
      const facetsResults = Object.entries(facets || {}).reduce<Record<string, Record<string, number> | string[]>>(
        (facetsObj, currentFacet) => ({
          ...facetsObj,
          [currentFacet[0]]: Object.keys(currentFacet[1]),
        }),
        {},
      );

      return facetsResults;
    },
    [countryCode, getAlgoliaIndexes],
  );

  const fetchPromotionCars = async (algoliaIndex: string) => {
    const algoliaPromoData = await algoliaInstance.search(algoliaIndex, '', {});
    return algoliaPromoData;
  };
  return {
    instance,
    fetchAlgoliaData,
    fetchAlgoliaPopularCars,
    getAlgoliaIndexes,
    getAutoCompleteListingTitle,
    fetchAlgoliaSimilarCars,
    fetchPromotionCars,
  };
};

export default useAlgoliaService;

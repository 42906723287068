import algoliasearch, { SearchClient, SearchIndex } from 'algoliasearch';
import startCase from 'lodash/startCase';
import type { RequestOptions } from '@algolia/transporter';
import isEmpty from 'lodash/isEmpty';

import { LocationOptionProps } from '@source/pages/Home/components/FilterLocationButton/FilterButton';
import { FilterProps, SettingProps } from '@design-system/components/FiltersV1/FilterProps';
import Logger from '@source/services/Logger';
import { NEXT_PUBLIC_ALGOLIA_APP_ID } from '@source/constants/env';
import { TRegionKey } from '@source/interface';

export class AlgoliaService {
  client: SearchClient;

  indexesMap: Map<string, SearchIndex>;

  constructor(appId: string, apiKey: string) {
    // if (!appId ||!apiKey) {
    //   Logger.error('Algolia credentials are missing');
    // }

    this.client = algoliasearch(appId, apiKey);
    this.indexesMap = new Map(); // cache indexes
  }

  reinit = (appId: string, apiKey: string) => {
    if (!appId || !apiKey) {
      Logger.error('Algolia credentials are missing');
    }
    this.client = algoliasearch(appId, apiKey);
    this.indexesMap = new Map(); // cache indexes
  };

  getAlgoliaIndex = (indexName: string) => {
    const index = this.indexesMap.get(indexName);
    if (index) return index;

    const newIndex = this.client.initIndex(indexName);
    this.indexesMap.set(indexName, newIndex);
    return newIndex;
  };

  search = async (indexName: string, query: string, params: RequestOptions) => {
    const index = this.getAlgoliaIndex(indexName);
    return index.search(query, params);
  };

  searchFacetValues = async (indexName: string, facetName: string, query: string) => {
    const index = this.getAlgoliaIndex(indexName);
    return index.searchForFacetValues(facetName, query);
  };

  getObject = async (indexName: string, objectId: string) => {
    const index = this.getAlgoliaIndex(indexName);
    return index.getObject(objectId);
  };
}

const algoliaInstance = new AlgoliaService(NEXT_PUBLIC_ALGOLIA_APP_ID || '', '');

export default algoliaInstance;

export const getOptionsInFacets = (facets: Record<string, Record<string, number>> | undefined, facetName: string) => {
  const facetValues = facets?.[facetName] ? Object.keys(facets[facetName]) : [];
  facetValues.sort();

  return facetValues.map((value) => ({
    label: startCase(value.replace(/_/g, ' ')),
    value,
  }));
};

export const buildLocationAlgoliaQuery = (
  options: LocationOptionProps[],
  filterName: string,
  countryCode: TRegionKey | undefined,
) => {
  if (['sg'].includes(countryCode || '')) return '';
  const valueQuery =
    options?.reduce(
      (acc, { value }, index) => acc.concat(`${filterName}:"${value}"${index < options.length - 1 ? ' OR ' : ''}`),
      '',
    ) || '';

  return valueQuery ? `((${valueQuery}))` : '';
};

const getFilterQuery = (setting: SettingProps) => {
  const { joinCondition = 'OR' } = setting;

  const query = setting?.filters
    ?.map((filter: FilterProps) => {
      const {
        name,
        type,
        selectedOptions,
        isNumeric,
        isTrueOption,
        selectedBoolean,
        selectedMinInput,
        selectedMaxInput,
        selectedNumber,
      } = filter;

      switch (type) {
        case 'button':
        case 'checkbox':
        case 'select':
        case 'dropdown':
        case 'make_model': {
          const q = selectedOptions
            ? selectedOptions
                .map((opt) => {
                  if (opt.range) {
                    if (opt.range.min && opt.range.max) return `(${name}: ${opt.range.min} TO ${opt.range.max})`;
                    if (opt.range.min) return `(${name} >= ${opt.range.min})`;
                    if (opt.range.max) return `(${name} <= ${opt.range.max})`;
                  } else if (isTrueOption) {
                    if (opt.customValue) {
                      return opt.customValue;
                    }
                    return `${opt.value}=1`;
                  } else {
                    return isNumeric ? `${name}=${opt.value}` : `${name}:"${opt.value}"`;
                  }
                  return '';
                })
                .filter((f) => f !== '')
                .join(' OR ')
            : '';
          return `(${q})`;
        }

        case 'boolean': {
          if (selectedBoolean) {
            return `(${name}:${selectedBoolean})`;
          }
          return '()';
        }
        case 'range_input':
          if (selectedMinInput && selectedMaxInput)
            return filter?.valueMultiplier
              ? `(${name}: ${parseFloat(selectedMinInput.toString()) * filter.valueMultiplier} TO ${
                  parseFloat(selectedMaxInput.toString()) * filter.valueMultiplier
                })`
              : `(${name}: ${selectedMinInput} TO ${selectedMaxInput})`;
          if (selectedMinInput)
            return filter?.valueMultiplier
              ? `(${name} >= ${parseFloat(selectedMinInput.toString()) * filter.valueMultiplier})`
              : `(${name} >= ${selectedMinInput})`;
          if (selectedMaxInput)
            return filter?.valueMultiplier
              ? `(${name} <= ${parseFloat(selectedMaxInput.toString()) * filter.valueMultiplier})`
              : `(${name} <= ${selectedMaxInput})`;
          break;
        case 'number':
          if (selectedNumber || (name?.includes('inventory.number_of_owners') && selectedNumber === 0)) {
            if (filter.valueMultiplier) {
              return `(${name} ${filter.condition || '<='} ${
                filter.valueMultiplier * parseFloat(selectedNumber.toString())
              })`;
            }
            return `(${name} ${filter.condition || '<='} ${selectedNumber})`;
          }
          break;
        default:
          break;
      }
      return '';
    })
    .filter((f) => f !== '()')
    .filter((f) => !isEmpty(f))
    .join(` ${joinCondition} `);
  return `(${query})`;
};

export const buildAlgoliaFilter = (filterSettings: SettingProps[]) => {
  const groupedFiltersQuery = filterSettings
    .filter((setting) => setting.name === 'make' || setting.name === 'make_model')
    .map(getFilterQuery)
    .filter((f) => f !== '()')
    .join(' OR ');

  // Filters that are joined by AND condition e.g. mileage AND depreciation
  const individualFiltersQuery = filterSettings
    .filter((setting) => setting.name !== 'make' && setting.name !== 'make_model')
    .map(getFilterQuery)
    .filter((f) => f !== '()')
    .join(' AND ');

  if (individualFiltersQuery && groupedFiltersQuery) {
    return `${individualFiltersQuery} AND (${groupedFiltersQuery})`;
  }

  return individualFiltersQuery || groupedFiltersQuery || '';
};

export const buildAlgoliaFilterCarsForSale = () =>
  `((listing.flags.is_coming_soon=0 AND listing.flags.is_sold=0 AND listing.flags.is_pending_sale=0))`;

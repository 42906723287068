import {
  NEXT_PUBLIC_ALGOLIA_SHOWROOM_SG,
  NEXT_PUBLIC_ALGOLIA_SHOWROOM_MY,
  NEXT_PUBLIC_ALGOLIA_SHOWROOM_ID,
  NEXT_PUBLIC_ALGOLIA_SHOWROOM_TH,
  NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_SG,
  NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_MY,
  NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_ID,
  NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_TH,
  NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_SG,
  NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_MY,
  NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_ID,
  NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_TH,
  NEXT_PUBLIC_ALGOLIA_API_KEY_SG,
  NEXT_PUBLIC_ALGOLIA_API_KEY_MY,
  NEXT_PUBLIC_ALGOLIA_API_KEY_ID,
  NEXT_PUBLIC_ALGOLIA_API_KEY_TH,
  NEXT_PUBLIC_ALGOLIA_API_KEY_JP,
  NEXT_PUBLIC_ALGOLIA_SHOWROOM_JP,
  NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_JP,
  NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_JP,
  NEXT_PUBLIC_ALGOLIA_SHOWROOM_HK,
  NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_HK,
  NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_HK,
  NEXT_PUBLIC_ALGOLIA_API_KEY_HK,
} from '@source/constants/env';

export type TSort = {
  asc: string;
  desc: string;
};

const algoliaIndexesSG = {
  latest: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_SG}_sort_newest`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_SG}_sort_newest`,
  },
  price: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_SG}_sort_price_asc`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_SG}_sort_price_desc`,
  },
  depreciation: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_SG}_sort_depreciation_asc`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_SG}_sort_depreciation_desc`,
  },
  mileage: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_SG}_sort_mileage_asc`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_SG}_sort_mileage_desc`,
  },
  instalment: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_SG}_sort_installment_asc`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_SG}_sort_installment_desc`,
  },
  reg_year: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_SG}_sort_registration_year_asc`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_SG}_sort_registration_year_desc`,
  },
};

const algoliaIndexesMY = {
  latest: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_MY}_sort_newest`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_MY}_sort_newest`,
  },
  price: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_MY}_sort_price_asc`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_MY}_sort_price_desc`,
  },
  depreciation: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_MY}_sort_depreciation_asc`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_MY}_sort_depreciation_desc`,
  },
  mileage: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_MY}_sort_mileage_asc`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_MY}_sort_mileage_desc`,
  },
  instalment: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_MY}_sort_installment_asc`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_MY}_sort_installment_desc`,
  },
  reg_year: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_MY}_sort_registration_year_asc`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_MY}_sort_registration_year_desc`,
  },
};

const algoliaIndexesID = {
  available: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_ID}_sort_availability`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_ID}_sort_availability`,
  },
  latest: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_ID}_sort_newest`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_ID}_sort_newest`,
  },
  price: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_ID}_sort_price_asc`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_ID}_sort_price_desc`,
  },
  depreciation: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_ID}_sort_depreciation_asc`,
    desc: `$${NEXT_PUBLIC_ALGOLIA_SHOWROOM_ID}_sort_depreciation_desc`,
  },
  mileage: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_ID}_sort_mileage`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_ID}_sort_mileage`,
  },
  instalment: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_ID}_sort_installment_asc`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_ID}_sort_installment_desc`,
  },
  reg_year: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_ID}_sort_registration_year_asc`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_ID}_sort_registration_year_desc`,
  },
};

const algoliaIndexesTH = {
  latest: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_TH}`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_TH}`,
  },
  price: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_TH}_sort_price_asc`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_TH}_sort_price_desc`,
  },
  depreciation: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_TH}_sort_depreciation_asc`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_TH}_sort_depreciation_desc`,
  },
  mileage: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_TH}_sort_mileage_asc`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_TH}_sort_mileage_desc`,
  },
  instalment: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_TH}_sort_installment_asc`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_TH}_sort_installment_desc`,
  },
  reg_year: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_TH}_sort_year_asc`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_TH}_sort_year_desc`,
  },
};

// Algolia indexes for Japan
const algoliaIndexesJP = {
  latest: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_JP}_sort_newest`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_JP}_sort_newest`,
  },
  price: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_JP}_sort_price_asc`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_JP}_sort_price_desc`,
  },
  mileage: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_JP}_sort_mileage_asc`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_JP}_sort_mileage_desc`,
  },
  reg_year: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_JP}_sort_registration_year_asc`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_JP}_sort_registration_year_desc`,
  },
};

// Algolia indexes for HongKong
const algoliaIndexesHK = {
  latest: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_HK}_sort_newest`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_HK}_sort_newest`,
  },
  price: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_HK}_sort_price_asc`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_HK}_sort_price_desc`,
  },
  mileage: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_HK}_sort_mileage_asc`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_HK}_sort_mileage_desc`,
  },
  reg_year: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_HK}_sort_registration_year_asc`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_HK}_sort_registration_year_desc`,
  },
  manufacture_year: {
    asc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_HK}_sort_manufacture_year_asc`,
    desc: `${NEXT_PUBLIC_ALGOLIA_SHOWROOM_HK}_sort_manufacture_year_desc`,
  },
};

export const ALGOLIA_INDEX_POPULAR_BY_COUNTRY: { [key: string]: string } = {
  sg: NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_SG || '',
  my: NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_MY || '',
  id: NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_ID || '',
  th: NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_TH || '',
  jp: NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_JP || '',
  hk: NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_HK || '',
};

export const ALGOLIA_INDEX_SIMILAR_CARS_BY_COUNTRY: { [key: string]: string } = {
  sg: NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_SG || '',
  my: NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_MY || '',
  id: NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_ID || '',
  th: NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_TH || '',
  jp: NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_JP || '', // todo: update key for jp
  hk: NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_HK || '',
};

export const ALGOLIA_INDEXES_COUNTRIES = {
  sg: algoliaIndexesSG,
  my: algoliaIndexesMY,
  id: algoliaIndexesID,
  th: algoliaIndexesTH,
  jp: algoliaIndexesJP,
  hk: algoliaIndexesHK,
};

export const ALGOLIA_API_KEY_MAP = {
  sg: NEXT_PUBLIC_ALGOLIA_API_KEY_SG,
  my: NEXT_PUBLIC_ALGOLIA_API_KEY_MY,
  id: NEXT_PUBLIC_ALGOLIA_API_KEY_ID,
  th: NEXT_PUBLIC_ALGOLIA_API_KEY_TH,
  jp: NEXT_PUBLIC_ALGOLIA_API_KEY_JP,
  hk: NEXT_PUBLIC_ALGOLIA_API_KEY_HK,
};
